import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Page/Home";
import About from "./Page/About";
import Services from "./Page/Services";
import OurApproach from "./Page/OurApproach";
import Experties from "./Page/Experties";
import Contact from "./Page/Contact";


function App() {
  return (
   <>
         <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/Services" element={<Services/>} />
        <Route path="/approach" element={<OurApproach/>} />
        <Route path="/expertise" element={<Experties/>} />
        <Route path="/contact" element={<Contact/>} />





     









      




        



        




        
        
    




  
      </Routes>
    </Router>
   </>
  );
}

export default App;
