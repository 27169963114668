import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import img1 from '../Assets/47103.jpg'
import about from '../Assets/10559.jpg'
import img2 from '../Assets/490-removebg-preview.png'
import { Link } from 'react-router-dom'
import whatsapp from '../Assets/WhatsApp_icon.png.webp'

function About() {
  return (
    <>
        <Navbar/>
        <Link to='https://api.whatsapp.com/send?phone=917820870526'>

        <img className='whatsapp' src={whatsapp}/>
        </Link>
        <div className='banner'>
        <div className='banner-left'>
            <h1>About Us<span> Niyara </span> Consulting</h1>
            <br/>
            <h3>Your Partner in Strategic Staffing and Talent Management</h3>
            <br/>
            <p>At Niyara Consulting, we understand that the right talent is crucial for business success. Whether you’re a small startup or a large corporation, finding the perfect candidate can be a daunting challenge. This is where Niyara Consulting excels.</p>
            <br/>
        <div className='banner-btns'>
                <Link to="/contact">
            <button className='getstart'>Get Started</button>
            </Link>
            {/* <button className='learnmore'>Learn More</button> */}

        </div>
        </div>
        <div className='banner-right banner-right2'>
<img src={img1}/>
        </div>

        </div>
        <div className='aboutus'>
        <div className='aboutus-right'>
{/* <h3>About US</h3>
<br/> */}
<h2>Our Values</h2>
<br/>
<p><b>Integrity:</b> We prioritize unwavering honesty and transparency in every interaction, ensuring trust and reliability.</p>
<br/>
<p><b>Excellence:</b> We strive to provide superior services that go beyond expectations, consistently aiming for the highest standards.</p>
<br/>
<p><b>Collaboration:</b> We foster enduring relationships with our clients and candidates, emphasizing teamwork and mutual growth.</p>
<br/>
<p><b>Innovation:</b> We leverage the latest technologies and innovative approaches to stay at the forefront of the ever-evolving recruitment landscape.</p>

<br/>
{/* <button className='getstart'>Learn More</button> */}

</div>
        <div className='aboutus-left banner-right2'>
<img src={about}/>
</div>

        </div>
        <div className='whyus'>
        <div className='whyus-right'>
<img src={img2}/>
</div>
 <div className='whyus-left whyus-left2'>

 <h2>Why Choose Niyara Consulting?</h2>




<br/>

<h5>Global Reach, Local Expertise: </h5>
<p>With operations in both India and overseas, we offer a global perspective combined with deep local market knowledge. Our extensive network and industry insights enable us to connect you with the best talent across the globe.</p>
<br/>
<h5>Customized Solutions: </h5>
<p>We recognize that each business is unique. Our tailored solutions are designed to meet your specific needs, ensuring that you get the right talent for the right roles.
</p>
<br/>
<h5>Experienced Professionals: </h5>
<p>Our team comprises seasoned professionals with extensive experience in various industries. We bring expertise, dedication, and a commitment to excellence to every project.
</p>
<br/>
<h5>Comprehensive Services: </h5>
<p>From recruitment and staffing to executive search, RPO, payroll services, and digital marketing, we provide a full spectrum of HR and digital solutions to support your business growth.
</p>


 
 </div>
 
 </div>
 <div className='toprated toprated2'>
 <h2>
 Our Team
 </h2>
 <br/>
 <p>At Niyara Consulting, our team is our greatest asset. We are a diverse group of passionate and skilled professionals dedicated to delivering exceptional results. Our collective expertise spans various industries, allowing us to understand and address your unique challenges effectively.</p>
 <br/>
 <p>Partner with Niyara Consulting and experience the difference. Let us help you navigate the complexities of talent acquisition and digital growth with confidence and ease. Your success is our mission.</p>
 <br/>

 <br/>
 <br/>
 </div>
        <Footer/>
    </>
  )
}

export default About