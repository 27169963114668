import React from 'react'
import logo from '../Assets/NC.png'
import icon1 from '../Assets/Facebook.svg'
import icon2 from '../Assets/Instagram.svg'
import icon3 from '../Assets/Linkedin.svg'
import icon4 from '../Assets/Twitter.svg'
import icon5 from '../Assets/Youtube.svg'
import { Link } from 'react-router-dom'

function Footer() {
  return (
   <>
    <div className='footer'>
    <ul className='footer-ul1'>
        <img className='logo' src={logo}/>

        <p>We bridge the gap between talent and opportunity, delivering comprehensive HR and digital solutions tailored to your unique needs. </p>

<br/>
<h6>Get In Touch</h6>
        <div className='footerconnect'>
        <i class="bi bi-telephone-fill"></i>
        <h5>+91 7820870526</h5>
        <h5>+91 9359867168</h5>



        
        </div>

        <div  className='footerconnect'>
      
        <i class="bi bi-envelope-fill"></i>
        <h5>info@niyaraconsulting.com</h5>
        </div>

    </ul>
    <ul>
    Quick links
    <br/>
    <br/>

    <Link style={{color: "unset", textDecoration: "none"}}  to="/">
        <li>Home</li>
      </Link>
<Link  style={{color: "unset", textDecoration: "none"}} to="/about">
        <li >About us</li>
      </Link>
      <Link to="/expertise"  style={{color: "unset", textDecoration: "none"}}>
        <li >Expertise</li>
      </Link>
      <Link to="/approach"  style={{color: "unset", textDecoration: "none"}}>
        <li >Approach</li>
      </Link>

    </ul>
    <ul>
    Reach Out
    <br/>
    <br/>

<Link to="/contact"  style={{color: "unset", textDecoration: "none"}}>
        <li>Contact</li>
      </Link>
      <Link to="/Services"  style={{color: "unset", textDecoration: "none"}}>
        <li>Services</li>
      </Link>

    </ul>
    <ul>
    Social media
    <br/>
    <br/>

    <div>
 
<Link to="https://www.facebook.com/profile.php?id=61559131402189&mibextid=ZbWKwL">
        <img src={icon1}/>
     </Link>
     <Link to="https://www.linkedin.com/company/niyara-consulting/">
        <img src={icon3}/>
   </Link>
    </div>
   

    </ul>

    </div>
    <div className='bottom-footer'>
<p>Designed & Developed by <Link className='bottofooterlink' to='https://www.strixdigital.in/'>Strix Digital</Link> </p>
    </div>
   </>
  )
}

export default Footer