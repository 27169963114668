import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import icon1 from '../Assets/cooperate-svgrepo-com 1.svg'
import i1 from '../Assets/i1 (1).svg'
import i2 from '../Assets/i1 (2).svg'
import i3 from '../Assets/i1 (3).svg'
import i4 from '../Assets/i1 (4).svg'
import i5 from '../Assets/i1 (5).svg'
import i6 from '../Assets/i1 (6).svg'
import icon2 from '../Assets/insight-svgrepo-com 1.svg'
import icon3 from '../Assets/idea-bulb-light-svgrepo-com 1.svg'
import icon4 from '../Assets/s1 (4).svg'
import icon5 from '../Assets/s1 (5).svg'
import icon6 from '../Assets/s1 (6).svg'
import whatsapp from '../Assets/WhatsApp_icon.png.webp'
import { Link } from 'react-router-dom'
import s1 from '../Assets/ITS/s1-removebg-preview.png'
import s2 from '../Assets/ITS/s2-removebg-preview.png'
import s3 from '../Assets/ITS/s3-removebg-preview.png'
// import s4 from '../Assets/ITS/s4-removebg-preview.png'
import s5 from '../Assets/ITS/s5-removebg-preview.png'
import s6 from '../Assets/ITS/s6-removebg-preview.png'
import s7 from '../Assets/ITS/s7-removebg-preview.png'
import s8 from '../Assets/ITS/s8-removebg-preview.png'
import s9 from '../Assets/ITS/s9-removebg-preview.png'
import s10 from '../Assets/ITS/s10-removebg-preview.png'



function Experties() {
  return (
    <>
        <Navbar/>
        <Link to='https://api.whatsapp.com/send?phone=917820870526'>

<img className='whatsapp' src={whatsapp}/>
</Link>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className='ourfeatures'>
 <div className='ourfeatures-div'>
 <h4>Expertise</h4>
 <h2>We are the best company to what we provide</h2>
 <br/>
 <br/>
 <div className='ourfeatures-cards'>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={s1}/>
 </div>

 <h3>IT</h3>
<p>Unlock the future with top-tier tech talent, from innovative software developers to dedicated IT support. We ensure your business stays ahead in the ever-evolving digital landscape.</p>

</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={s2}/>
 </div>

 <h3>Manufacturing</h3>
<p>Enhance your production line with skilled professionals in quality control, engineering, and management. We power your manufacturing operations for maximum efficiency and excellence.</p>

</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={s3}/>
 </div>

 <h3>Education</h3>
<p>Inspire the next generation with passionate educators and efficient administrative staff. From K-12 to higher education, we help create vibrant learning environments.</p>
</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={s5}/>
 </div>

 <h3>BFSI</h3>
<p>Strengthen your financial, banking, and insurance sectors with expert analysts, savvy bankers, and seasoned insurance professionals. We safeguard your financial success.</p>
</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={s6}/>
 </div>

 <h3>Hospitality & Healthcare</h3>
<p>Elevate care and service with compassionate healthcare staff and exceptional hospitality professionals. From hospitals to hotels, we enhance customer satisfaction and operational excellence.</p>
</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={s7}/>
 </div>

 <h3>Automobile</h3>
<p>Accelerate your growth with expert engineers, production specialists, and dynamic sales teams. We drive your automotive industry towards innovation and success.</p>
</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={s8}/>
 </div>

 <h3>Oil & Gas</h3>
<p>Fuel your energy sector with top technical and managerial talent for exploration, production, and distribution. We power your progress in the oil and gas industry.</p>
</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={s9}/>
 </div>

 <h3>Sales & Marketing</h3>
<p>Boost your market presence and revenue with dynamic sales representatives and creative marketing professionals. We amplify your brand and drive your business forward.</p>
</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={s10}/>
 </div>

 <h3>Retail & Real Estate</h3>
<p>Optimize your operations with sales experts, customer service professionals, and property managers. We transform your retail and real estate ventures for peak performance.</p>
</div>

 </div>


</div>
 </div>
        <Footer/>
    </>
  )
}

export default Experties