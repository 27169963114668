import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import icon1 from '../Assets/ITS/r1-removebg-preview.png'
import icon2 from '../Assets/ITS/r2-removebg-preview.png'
import icon3 from '../Assets/ITS/r3-removebg-preview.png'
import icon4 from '../Assets/ITS/r4-removebg-preview.png'
import icon5 from '../Assets/ITS/r5-removebg-preview.png'
import icon6 from '../Assets/ITS/r6-removebg-preview.png'

import whatsapp from '../Assets/WhatsApp_icon.png.webp'
import { Link } from 'react-router-dom'

function Services() {
  return (
   <>
    <Navbar/>
    <Link to='https://api.whatsapp.com/send?phone=917820870526'>

<img className='whatsapp' src={whatsapp}/>
</Link>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <div className='ourfeatures'>
 <div className='ourfeatures-div'>
 <h4>Services</h4>
 <h2>We are the best company to what we provide</h2>
 <br/>
 <br/>
 <div className='ourfeatures-cards'>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={icon1}/>
 </div>

 <h3>Recruitment
 Services</h3>
<p>Discover the perfect candidates for your business. Our expert recruiters identify and engage top talent, ensuring a seamless match for your company’s culture and requirements.</p>

</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={icon2}/>
 </div>

 <h3>Staffing Service</h3>
<p>Flexible staffing solutions to meet your dynamic business needs. From temporary to permanent placements, we provide the right talent at the right time to keep your operations running smoothly.</p>

</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={icon3}/>
 </div>

 <h3>Executive Search</h3>
<p>Find visionary leaders to drive your organization forward. Our executive search service connects you with high-Caliber professionals who bring strategic insights and impactful leadership to your business.</p>
</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={icon4}/>
 </div>

 <h3>Recruitment Process Outsourcing (RPO): </h3>
<p>Streamline your hiring process with our comprehensive RPO services. We manage the entire recruitment lifecycle, allowing you to focus on your core business while we deliver top-tier talent efficiently.</p>
</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={icon5}/>
 </div>

 <h3>Payroll Services</h3>
<p>Simplify your payroll management with our accurate and reliable services. We handle all aspects of payroll processing, ensuring compliance and timely payments, giving you peace of mind and more time to focus on growth.</p>
</div>
<div className='ourfeatures-card'>
 <div className='fet-icon'>
<img className='exp-icon' src={icon6}/>
 </div>

 <h3>Digital Marketing and Web Design</h3>
<p>Elevate your online presence with our cutting-edge digital marketing and web design services. From SEO to social media strategies and visually stunning websites, we help your brand stand out in the digital world.</p>
</div>



 </div>


</div>
 </div>
    <Footer/>
   </>
  )
}

export default Services