import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import about from '../Assets/66198270_9629726.jpg'
import img2 from '../Assets/approch.jpg'
import { Link } from 'react-router-dom'
import img3 from '../Assets/sss1 (1).jpg'
import img4 from '../Assets//sss1 (2).jpg'
import img5 from '../Assets/sss1 (3).jpg'
import whatsapp from '../Assets/WhatsApp_icon.png.webp'


function OurApproach() {
  return (
    <>
        <Navbar/>
        <Link to='https://api.whatsapp.com/send?phone=917820870526'>

<img className='whatsapp' src={whatsapp}/>
</Link>
        <br/>
    <br/>
    <br/>
  
    <br/>
    <br/>
    <br/>
   
 
    <div className='aboutus'>
     
 <div className='aboutus-right'>
<h3>Our Approach</h3>
<br/>
<h2>Niyara Consulting- Connecting Excellence, Simplifying Success</h2>
<br/>
<p>At Niyara Consulting, our approach is meticulously crafted to ensure that we deliver exceptional talent and services that align perfectly with your business needs. Our four-step process—Discovery, Sourcing, Evaluation, and Onboarding & Support guarantees a seamless and efficient recruitment experience.</p>
<br/>
<Link to="/contact">
            <button className='getstart'>Get Started</button>
            </Link>
</div>
   <div className='aboutus-left'>
<img src={about}/>
</div>
        </div>
        <div className='new-cards'>
        <div className='new-card'>
        <img src={img3}/>
<div className='new-card-text'>
<h3>Attracting Top Talent</h3>
<p>Leveraging our extensive network and advanced sourcing technologies, we attract and identify highly qualified candidates. Our sourcing strategy includes tapping into various channels such as professional networks, social media, job boards, and industry-specific platforms. This multi-faceted approach ensures we reach a diverse and talented pool of candidates.</p>

</div>
</div>
    <div className='new-card'>
        <img src={img4}/>
<div className='new-card-text'>
<h3>Comprehensive Assessments:</h3>
<p>We believe in a thorough evaluation process to ensure every candidate we present is a perfect fit for both the role and your company culture. Our assessments include detailed interviews, skill tests, background checks, and personality evaluations. We focus on both technical skills and cultural compatibility, providing you with candidates who are ready to excel in their roles.</p>

</div>
</div>
   
    <div className='new-card'>
        <img src={img5}/>
<div className='new-card-text'>
<h3>Seamless Integration</h3>
<p>Our commitment to your success doesn’t end with the hiring decision. We provide ongoing support to ensure a smooth transition for both the candidate and your organization. Our onboarding process is designed to integrate new hires quickly and effectively, helping them feel welcomed and prepared to contribute from day one. Additionally, we offer continued support and follow-ups to address any concerns and facilitate long-term success.</p>

</div>
</div>
 <div className='new-card'>
        <img src={img3}/>
<div className='new-card-text'>
<h3>Experience the Niyara Difference</h3>
<p>At Niyara Consulting, we are dedicated to building lasting partnerships based on trust, transparency, and exceptional service. Our approach is not just about filling positions; it’s about finding the right people who will drive your business forward. Let us be your trusted partner in navigating the complexities of talent acquisition and digital growth. Together, we can achieve great things.</p>

</div>
</div>
        </div>
        <Footer/>
    </>
  )
}

export default OurApproach