import React, { useRef } from 'react';
import emailjs from '@emailjs/browser'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import whatsapp from '../Assets/WhatsApp_icon.png.webp'
import { Link } from 'react-router-dom';
function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_fe16l9y', 'template_qau4hov', form.current, {
        publicKey: 'uc6f-Eb6kTvk0xsRp',
      })
      .then(
        () => {
          alert('We will reach out to you soon.');
          form.current.reset(); // Reset the form on successful submission
        },
        (error) => {
          alert(`Failed to send email: ${error.text}`);
        }
      );
  };

  return (
    <>
        <Navbar/>
        <Link to='https://api.whatsapp.com/send?phone=917820870526'>

<img className='whatsapp' src={whatsapp}/>
</Link>
        <div className='contactus'>
        <div className='contactus-left'>
        <div className='address-label'>
            <div className='circle'>
            <i class="bi bi-geo-alt"></i>
            </div>
          <div>
          <label>Address</label>
           
           <p>Office 11, Payal Apartment<br/>
Sanghvi Nagar, Aundh<br/>
Pune 411007
</p>
          </div>
        </div>
     
        <div className='address-label'>
            <div className='circle'>
            <i class="bi bi-envelope"></i>
            </div>
          <div>
          <label>Email</label>
           
           <p>info@niyaraconsulting.com</p>
          </div>
        </div>
        <div className='address-label'>
            <div className='circle'>
            <i class="bi bi-telephone"></i>
            </div>
          <div>
          <label>Phone</label>
           
           <p>+91 7820870526</p>
           <p>+91 9359867168</p>
          </div>
        </div>

</div>
   <div className='contactus-right'>
   <h1>Get In Touch</h1>
   <br/>
   <br/>
   <form ref={form} onSubmit={sendEmail}>
            <label>Name</label>
            <br />
            <input type="text" name="user_name" placeholder='Name' required />
            <br />
            <br />
            <label>Email Address</label>
            <br />
            <input type="email" name="user_email" placeholder='Enter Your Email Address' required />
            <br />
            <br />
            <label>Phone Number</label>
            <br />
            <input name="phone" placeholder='Enter Your Phone Number' required />
            <br />
            <br />
            <label>Message</label>
            <br />
            <textarea rows="5" name="message"  required />
            <br />
            <br />

           
            <input type="submit" value="Send" className='getstart getstart2' />
          </form>
</div>
        </div>
        <Footer/>
    </>
  )
}

export default Contact