import React, { useState } from 'react';

import logo from '../Assets/Niyara_Logo-removebg-preview.png'
import { Link } from 'react-router-dom'

function Navbar() {
  const [isDivVisible, setIsDivVisible] = useState(false);

  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);
  };
  return (
    <>
        <nav>
            <div className='navbar'>
              <Link to="/">
            <img className='logo' src={logo}/>
            </Link>
            <ul>
            <Link className='none' to='/'>

                <li >Home</li>
            </Link>

                <Link className='none' to='/about'>
              
                <li>About Us</li>
              </Link>

                <Link className='none' to='/Services'>
              
                <li>Our Services</li>
              </Link>
                <Link className='none' to='/approach'>
              
                <li>Our Approach</li>
              </Link>
                <Link className='none' to='/expertise'>
              
                <li>Expertise</li>
              </Link>
              

            </ul>
            <Link to='/contact'>

<button className='navbar-button'>Contact Us</button>

            </Link>
            <i class="bi bi-list" onClick={toggleDivVisibility}></i>

            </div>
        </nav>
        {isDivVisible && (
        <div className="toggleDiv-ops">
        <div className="toggleDiv">
          {/* Content of the div goes here */}
          <ul>
          <Link className='none' to='/'>

<li >Home</li>
</Link>

<Link className='none' to='/about'>

<li>About Us</li>
</Link>

<Link className='none' to='/Services'>

<li>Our Services</li>
</Link>
<Link className='none' to='/approach'>

<li>Our Approach</li>
</Link>
<Link className='none' to='/expertise'>

<li>Expertise</li>
</Link>
   <Link to='/contact'>

<button className='navbar-button'>Contact Us</button>

            </Link>
            <br/>
        <img src={logo}/>

        </ul>
        </div>
        </div>
      )}
    </>
  )
}

export default Navbar