import React from 'react'
import Navbar from '../Components/Navbar'
import img1 from '../Assets/homepic.svg'
import about from '../Assets/about g.svg'
import icon1 from '../Assets/cooperate-svgrepo-com 1.svg'
import icon2 from '../Assets/insight-svgrepo-com 1.svg'
import icon3 from '../Assets/idea-bulb-light-svgrepo-com 1.svg'
import icon4 from '../Assets/s1 (4).svg'
import icon5 from '../Assets/s1 (5).svg'
import icon6 from '../Assets/s1 (6).svg'
import img2 from '../Assets/image 11.svg'
import img3 from '../Assets/Group 21.svg'
import test from '../Assets/Group 33.svg'
import comma from '../Assets/image 16.svg'
import blog from '../Assets/image 20.svg'
import i1 from '../Assets/i1 (1).svg'
import i2 from '../Assets/i1 (2).svg'
import i3 from '../Assets/i1 (3).svg'
import i4 from '../Assets/i1 (4).svg'
import i5 from '../Assets/i1 (5).svg'
import i6 from '../Assets/i1 (6).svg'
import Footer from '../Components/Footer'
import { Link } from 'react-router-dom'
import whatsapp from '../Assets/WhatsApp_icon.png.webp'

function Home() {
  return (
    <>
        <Navbar/>
        <Link to='https://api.whatsapp.com/send?phone=917820870526'>

        <img className='whatsapp' src={whatsapp}/>
        </Link>
        <div className='banner'>
        <div className='banner-left'>
            <h1>Niyara Consulting<span> Connecting Excellence,</span> Simplifying Success.</h1>
            <br/>
            <p>At Niyara Consulting, we bridge the gap between talent and opportunity, delivering comprehensive HR and digital solutions tailored to your unique needs. With a strong presence in India and overseas, we serve a diverse range of clients across the globe.</p>
            <br/>
        <div className='banner-btns'>
        <Link to="/contact">
            <button className='getstart'>Get Started</button>
            </Link>            {/* <button className='learnmore'>Learn More</button> */}

        </div>
        </div>
        <div className='banner-right'>
<img src={img1}/>
        </div>

        </div>
        <div className='aboutus'>
        <div className='aboutus-left'>
<img src={about}/>
</div>
 <div className='aboutus-right'>
<h3>About US</h3>
<br/>
<h2>Your Partner in Strategic Staffing and Talent Management</h2>
<br/>
<p>At Niyara Consulting, we understand that the right talent is crucial for business success. Whether you’re a small startup or a large corporation, finding the perfect candidate can be a daunting challenge. This is where Niyara Consulting excels.</p>
<br/>

<Link to="/contact">
            <button className='getstart'>Get Started</button>
            </Link>
</div>
        </div>
        
 
 <div className='ourfeatures'>
 <div className='ourfeatures-div'>
 <h4>How we do it?</h4>
 {/* <h2>Why work with anyone else? </h2> */}
 <br/>
 <br/>
 <div className='ourfeatures-cards'>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img src={icon2}/>
 </div>

 <h3>Identifying
 Candidates</h3>
<p>Leveraging our vast
network to find qualified
professionals.
</p>
</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img src={icon1}/>
 </div>

 <h3>Shortlisting &
Screening
</h3>
<p>Meticulously selecting
candidates based on
experience, skills, and
cultural fit.</p>
</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img src={icon3}/>
 </div>

 <h3>Placement &
 Follow-Up</h3>
<p>Ensuring continuous
support and
monitoring to
uphold service
quality</p>
</div>
 </div>


</div>
 </div>

 <div className='whyus'>
 <div className='whyus-left'>

 <h2>Why <span> Niyara </span>
 Consulting ?</h2>




<br/>

<h5>Global Reach, Local Expertise</h5>
<p>Our worldwide network,
combined with local market
insights, positions us uniquely
to attract top talent, no matter
where they are.</p>
<br/>
<h5>Comprehensive Role Coverage</h5>
<p>We specialize in filling a wide array
of roles, from entry-level positions to
top executives, across various sectors
including IT, finance, healthcare, and
more.
</p>
<br/>
<h5>Customized Recruitment Strategies</h5>
<p>Understanding that no two
businesses are alike, we tailor our
recruitment solutions to fit your
unique organizational culture, goals,
and requirements.
</p>
<br/>
<h5>Innovative Sourcing Techniques</h5>
<p>Leveraging the latest in
recruitment technology and
methodologies, we ensure access
to a broad and diverse talent pool.
</p>




<br/>
 <Link to='/contact'>

<button className='getstart'>Get Started</button>
 </Link>
 
 </div>
 <div className='whyus-right'>
<img src={img2}/>
</div>
 </div>
 
 {/* <div className='ourfeatures'>
 <div className='ourfeatures-div'>
 <h4>Our Solution</h4>
 <h2>We are the best company to what we provide</h2>
 <br/>
 <br/>
 <div className='ourfeatures-cards'>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img src={icon2}/>
 </div>

 <h3>Recruitment
 Services</h3>
<p>Executive Search</p>
<p>Permanent Staffing</p>
<p>Temporary Staffing</p>

</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img src={icon1}/>
 </div>

 <h3>Staffing
 Solution</h3>
<p>Contract Staffing</p>
<p> Project-Based
Staffing</p>
<p>Outsourced
Recruitment
Solutions</p>

</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img src={icon3}/>
 </div>

 <h3>Payroll
 Services</h3>
<p>Simplify your
payroll. Our
comprehensive
services manage
everything from
processing to tax
filing, seamlessly</p>
</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img src={icon5}/>
 </div>

 <h3>Accounting
 Services</h3>
<p>Keep your finances
in check. From book
keeping to financial
analysis, we ensure
accuracy and
compliance.</p>
</div>
 <div className='ourfeatures-card'>
 <div className='fet-icon'>
<img src={icon4}/>
 </div>

 <h3>Other
 Services</h3>
<p>Learning &
Development</p>
<p>Digital Marketing</p>
<p>Web Design</p>
<p>Content Writing</p>
</div>

 </div>


</div>
 </div> */}


{/* <div className='benifit'>
<div className='benifit-left'>
<h4>Industries We Serve</h4>
<br/>
<h2>We are connected all time to help your business.</h2>
<br/>
<p>Niyara Consulting caters to a diverse array of industries including technology, finance,
manufacturing, and hospitality, among others, offering tailored solutions and expert guidance
to meet the unique needs and challenges within each sector</p>
<br/>
<li>IT and SaaS
</li>
<li>Finance
And
Banking
</li>
<li>Manufacturing
Chemical
Automobile
Pharmaceutical </li>
<li>Hospitality
Retail
Health Care
Real Estate
</li>
<br/>
<div className='banner-btns'>
<Link to="/contact">
            <button className='getstart'>Get Started</button>
            </Link>

        </div>
</div>
<div className='benifit-right'>
<img src={img3}/>

</div>
</div>
   */}
<br/>
<br/>
<br/>

  {/* <div className='testimonials'>

 <div className='testimonials-heading'>
 <h4>Testimonial</h4>
 <h2>What Over Great Clients say about our work</h2>

 </div>
 <div className='review'>
 <div className='testimonials-left'>
<img src={test}/>
  </div>
  <div className='testimonials-right'>
  <img src={comma}/>
  <br/>
  <br/>

  <p>The business template was a game-changer for our brand. We loved the agency business template's flexibility. With its pre-designed sections and intuitive customization options, we saved valuable time and effort, resulting in a polished and standout website.</p>
<br/>
<br/>
<br/>
<h3>Colin Mathieu</h3>
<h4>Los Angeles, USA</h4>
</div>

 </div>
  </div> */}
 
{/* 
<br/>
<br/>
 <div className='blogs'>
 <p>Latest News & Blog</p>
 <h2>Browse our articles <br/>& resources</h2>
 <div className='blog-cards'>
 <div className='blog-card'>
 <img src={blog}/>
 <div className='blog-text'>
 <div className='blog-heading'>
 <button>Business</button>
 <p>July 3, 2023</p>

 </div>
 <br/>
 
 <h2>Success simplified business insights for growth</h2>
  
 </div>

 </div>
 <div className='blog-card'>
 <img src={blog}/>
 <div className='blog-text'>
 <div className='blog-heading'>
 <button>Business</button>
 <p>July 3, 2023</p>

 </div>
 <br/>
 
 <h2>Success simplified business insights for growth</h2>
  
 </div>

 </div>
 <div className='blog-card'>
 <img src={blog}/>
 <div className='blog-text'>
 <div className='blog-heading'>
 <button>Business</button>
 <p>July 3, 2023</p>

 </div>
 <br/>
 
 <h2>Success simplified business insights for growth</h2>
  
 </div>

 </div>
 </div>

 </div> */}

 {/* <div className='toprated'>
 <h2>
 Top rated business company
 </h2>
 <br/>
 <p>From start-ups to fortune 100+ we partner with brands of all sizes</p>
 <br/>
 <br/>
 <div className='slide'>
 <div className='slide-card'>
<img src={i1}/>
 </div>
 <div className='slide-card'>
<img src={i2}/>
 </div>
 <div className='slide-card'>
<img src={i3}/>
 </div>
 <div className='slide-card'>
<img src={i4}/>
 </div>
 <div className='slide-card'>
<img src={i5}/>
 </div>
 <div className='slide-card'>
<img src={i6}/>
 </div>
 </div>
 <br/>
 <br/>
 </div> */}
 <Footer/>

    </>
  )
}

export default Home